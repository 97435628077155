@import '../../shared.scss';

$cell-pad: 0.75rem;

.optionButtons {
  display: flex;
  justify-content: center;
  border-left: 2px solid var(--bs-light);

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    font-size: 25px;
    margin-right: 5px;

    &.hidden {
      visibility: hidden;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.favoriteButton {
      svg {
        path {
          fill: transparent;
          stroke: currentColor;
          stroke-width: 50px;
        }
      }

      &.active {
        svg {
          path {
            fill: currentColor;
          }
        }
      }

      &:hover {
        svg {
          path {
            fill: currentColor;
          }
        }
      }
    }
  }
}
.newIconTable {
  left: -35px;
  top: -10px;
  scale: 1;
  position: absolute;
  transform: rotate(345deg);
  z-index: 90;
  scale: 0.5;
}
.newIconTableMobile {
  left: -54px;
  top: 12px;
  position: absolute;
  z-index: 90;
  scale: 0.3;
}

.newIconColumn {
  position: absolute;
  scale: 0.75;
  transform: rotate(355deg);
  left: -5px;
  top: -14px;
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
}

.highlightCell {
  font-size: 0.85rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.imageTitleColumn {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .imageContainer {
    display: flex;

    > div {
      width: 64px;
      height: 64px;
      margin-right: 15px;
      position: relative;

      img {
        border-radius: 5px;
        object-fit: cover;
        max-width: 64px;
        width: auto;
        display: block;
        margin: auto;
        height: 100%;
        background-color: #f1f1f1;
        mix-blend-mode: darken;
      }
    }
  }

  .imageHeader {
    position: absolute;
    height: auto;
    padding: 0.125rem 0rem 0rem 0.125rem;
  }

  p {
    margin-bottom: 0;
  }

  .price {
    height: auto;
    width: auto;
  }

  .sku {
    font-size: 0.8rem;
    opacity: 0.7;
  }
}

.quantityButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 2px solid var(--bs-light);

  margin-left: 0;
  margin-right: 0;

  :global(.col-input) {
    padding: 0;
    text-align: center;
    > div {
      margin-bottom: 0;
    }

    strong {
      color: var(--bs-secondary);
      font-size: 1.5rem;
    }
  }
}

.expandedRowToggle {
  border: none;
  background: transparent;
  font-size: 18px;
  display: flex;

  svg {
    font-size: 25px;
    transition: 0.2s ease-in-out;

    &.active {
      transform: rotate(-180deg);
    }
  }
}

.table {
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0 3px;

  thead {
    tr {
      th {
        background-color: #fff;
        &:first-of-type {
          border-radius: 1rem 0 0 0;
        }
        &:last-of-type {
          border-radius: 0 1rem 0 0;
          text-align: center;

          button {
            border: none;
            background-color: transparent;
            font-size: 20px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        background-color: #fff;
        &.tableInfo {
          text-align: center;
        }

        &:nth-last-child(2) {
          padding: 1rem 0;
        }

        span {
          span {
            margin-left: 5px;
            font-size: 12px;
          }
        }

        .price {
          .linethrough {
            color: inherit;
            opacity: 0.7;
            text-decoration: line-through;
            font-size: 13px;
          }

          p {
            color: var(--bs-secondary);
            font-weight: bold;
            margin-bottom: 0;
          }
        }
      }

      &:nth-last-child(2) {
        td {
          &:first-of-type {
            border-radius: 0 0 0 1rem;
          }

          &:last-of-type {
            border-radius: 0 0 1rem 0;
          }
        }
      }

      &.expandedRow {
        border: none;

        :global(.row) {
          margin: 0;
        }
      }
    }
  }

  & > :not(caption) > * > * {
    padding: $cell-pad;
    border-bottom-width: 0;
    box-shadow: none;
  }

  & > :not(:first-child) {
    border-top: none;
  }

  td {
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20rem;

    &:last-of-type {
      padding-left: 0;
      padding-right: 0;
    }
  }
  :global(.btn-link) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.grid {
  padding: 0.5rem;
  cursor: pointer;

  .gridHeader {
    padding: 0;
    position: relative;

    .headerButtons {
      position: absolute;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      padding: 0.5rem 0.5rem 0rem 0.5rem;
      img {
        height: 100%;
      }
      .favoriteButton {
        height: min-content;
      }
      .newIcon {
        height: 80px;
        margin: 0;
        right: 5px;
        bottom: 18px;
        position: relative;
        transform: rotate(345deg);
      }
    }

    .productImage {
      border-radius: 0.5rem;
      object-fit: cover;
      max-width: 100%;
      width: auto;
      display: block;
      margin: auto;
      height: 220px;
    }

    button {
      z-index: 10;
      background-color: #fdfdfdeb;
      border: none;
      border-radius: 3px;
      box-shadow: 0px 3px 6px #00000029;
      font-size: 1.125rem;
      color: var(--bs-primary);
      transition: 3s ease-in-out;

      svg {
        path {
          fill: transparent;
          stroke: currentColor;
          stroke-width: 50px;
        }
      }

      &.active {
        svg {
          path {
            fill: currentColor;
          }
        }
      }

      &:hover {
        svg {
          path {
            fill: currentColor;
          }
        }
      }
    }
  }

  .gridBody {
    padding: 0.5rem 0;

    .gridProductTitle {
      overflow: hidden;
      height: 50px;
    }

    :global(.card-text) {
      &:first-of-type {
        height: 56px;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &:last-of-type {
        opacity: 0.7;
        margin-bottom: 0;
      }
    }

    div {
      height: 55px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      p {
        margin-bottom: 0;
        opacity: 0.7;
        text-decoration: line-through;
        font-size: 14px;
      }
    }

    strong {
      font-size: 20px;
      color: var(--bs-secondary);

      span {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }
}

.inputQuantity {
  margin-bottom: 0.5rem;

  button {
    background-color: transparent;
    color: var(--bs-secondary);
    border: none;
    padding: 0.375rem 0.75rem;

    &[disabled] {
      opacity: 0.3;
    }
  }

  input[type='number'] {
    text-align: center;
    background-color: #fff;
    border: 1px solid $light-gray;
    border-radius: 0.5rem !important;
    color: var(--bs-primary);
    font-size: 20px;

    &:global(.is-invalid) {
      padding: 0.375rem 0.75rem;
    }
  }
}

.productQuantityVolumeDiscount {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  border-bottom: none;

  &.single {
    padding: 1rem 0;
    border-bottom: 1px solid #c9c9c9;

    .productVolumeDiscountRow {
      border-left: 2px solid var(--bs-secondary);
    }
  }

  .productQuantity {
    width: 250px;

    .inputQuantity {
      padding-right: 2rem;
    }
  }

  .productVolumeDiscountColumn,
  .productVolumeDiscountRow,
  .productVolumeDiscountRowReview {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    div {
      text-align: center;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      background-color: gray;
      color: #fff;
      margin-bottom: 1rem;
      opacity: 0.3;
      width: 100%;
      white-space: nowrap;

      &.active {
        opacity: 1;
        background-color: var(--bs-secondary);
      }

      p {
        margin: 0;

        &:first-of-type {
          font-weight: bold;
          font-size: 18px;
        }

        &:last-of-type {
          font-size: 13px;
        }
      }
    }
  }

  .productVolumeDiscountRow,
  .productVolumeDiscountRowReview {
    flex-direction: row;
    padding: 0 2rem;

    div {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }

  .productVolumeDiscountRowReview {
    padding: 0;
  }

  .productVolumeDiscountColumn {
    flex-direction: column;
    padding: 0;

    div {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
}

.pills {
  display: flex;
  margin-bottom: 0.5rem;

  div {
    &:first-of-type {
      border: 2px solid var(--bs-primary);
      padding: 0.3rem 1rem;
      border-radius: 20px;
      margin-right: 15px;
      background-color: #fff;
    }

    &:last-of-type {
      display: flex;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  button {
    border: 2px solid $light-gray;
    padding: 0.3rem 1rem;
    border-radius: 20px;
    margin-right: 15px;
    color: $light-gray;
    transition: 0.2s ease-in-out;
    scroll-snap-align: start;
    white-space: nowrap;

    &:hover {
      color: var(--bs-secondary);
      border-color: var(--bs-secondary);
    }

    &.active {
      color: #fff;
      border-color: var(--bs-secondary);
      background-color: var(--bs-secondary);
    }
  }
}

.tableCombination {
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0 5px;
  --bs-table-accent-bg: var(--bs-body-bg);
  thead {
    tr {
      th {
        color: var(--bs-primary);
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      .tableInfo {
        border-radius: 1rem !important;
      }

      td {
        text-align: center;
        background-color: #fff;

        &:first-of-type {
          border-radius: 1rem 0 0 1rem;
        }

        &:last-of-type {
          border-radius: 0 1rem 1rem 0;
          width: 200px;
        }
      }
    }
  }

  & > :not(caption) > * > * {
    padding: 0.3rem 0;
    border-bottom-width: 0;
    box-shadow: none;
  }

  & > :not(:first-child) {
    border-top: none;
  }

  td {
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :global(.btn-link) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.countProducts {
  p {
    font-size: 15px;
    color: #9e9e9e;
    margin-bottom: 0;

    span {
      color: var(--bs-primary);
    }
  }
}

.viewButtons {
  button {
    padding: 0.5rem 0.75rem;
    background-color: #fff;
    border: none;
    transition: 0.2s ease-in-out;

    &:first-of-type {
      border-radius: 12px 0 0 12px;
    }

    &:last-of-type {
      border-radius: 0 12px 12px 0;
    }

    &:disabled {
      box-shadow: inset 0px 0px 7px #0000001c;
      background-color: #fcfafa;

      svg {
        color: gray;
      }
    }

    svg {
      font-size: 20px;
      color: var(--bs-primary);
    }
  }
}

.mobileFilter {
  background-color: var(--bs-body-bg);
  box-shadow: 0 3pt 6pt #00000029 !important;
  border-radius: 12px !important;
  max-width: 90% !important;
  :global(.popover-body) {
    padding: 0;

    > div {
      background-color: #fff;
      padding: 1rem;
      margin-bottom: 0.25rem;

      &:first-of-type {
        border-radius: 12px 12px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 12px 12px;
        margin-bottom: 0;
      }

      &:only-of-type {
        border-radius: 12px;
        margin-bottom: 0;
      }
    }
  }
}

.categoryIndicator {
  hr {
    flex-basis: 0;
    border-width: 2px;
    border-color: var(--bs-secondary);
  }

  h2 {
    max-width: 90%;
    text-align: center;
  }
}

.breadcrumb {
  button {
    border: none;
    background-color: transparent;
    padding-left: 0;
    font-weight: bold;
  }
}

@media (max-width: 991px) {
  .quantityButtons {
    justify-content: end;
    border-left: none;

    :global(.col-input) {
      width: 100%;
      max-width: 200px;
    }
  }

  .newIconColumn {
    left: -5px;
    top: 26px;
    z-index: 90;
  }

  .imageTitleColumn {
    div {
      &:first-child {
        width: 64px;
        height: 64px;
        position: relative;
        margin-right: 5px;

        img {
          max-width: 64px;
        }

        button {
          position: absolute;
          top: 5px;
          right: 5px;
          z-index: 10;
          background-color: #fdfdfdeb;
          border: none;
          border-radius: 3px;
          box-shadow: 0px 3px 6px #00000029;
          padding: 0 5px;
          font-size: 12px;
          color: var(--bs-primary);
          transition: 0.3s ease-in-out;

          svg {
            path {
              fill: transparent;
              stroke: currentColor;
              stroke-width: 50px;
            }
          }

          &.active {
            svg {
              path {
                fill: currentColor;
              }
            }
          }

          &:hover {
            svg {
              path {
                fill: currentColor;
              }
            }
          }
        }
      }
    }

    a {
      width: auto;
      height: 100%;
      overflow-x: hidden;

      p {
        white-space: break-spaces;
        &:first-of-type {
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
        span {
          color: var(--bs-secondary);
          font-weight: bold;
        }
      }
    }
  }

  .productQuantityVolumeDiscount {
    .productVolumeDiscountColumn {
      margin-bottom: 1rem;
      width: 100%;

      div {
        margin-bottom: 5px;
        margin-right: 0;
        width: 100%;

        p {
          white-space: normal;
        }
      }
    }

    &.single {
      border-bottom: none;
      padding-bottom: 0;

      .productVolumeDiscountRowReview {
        padding: 0;
        max-width: 100%;
        width: 100%;
        flex-direction: column;

        div {
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .expandedRowToggle {
    justify-content: center;
    align-items: center;

    svg {
      margin-left: 0;
    }
  }

  .table {
    border-spacing: 0 5px;
    thead {
      display: none;
    }
    tbody {
      tr {
        td {
          padding: 0.25rem;
          max-width: 13rem;

          &:first-of-type {
            border-radius: 1rem 0 0 1rem;
          }

          &:last-of-type {
            border-radius: 0 1rem 1rem 0;
          }

          &:only-of-type {
            border-radius: 1rem;
          }
        }

        &:nth-last-child(2) {
          td {
            &:first-of-type {
              border-radius: 1rem 0 0 1rem;
            }

            &:last-of-type {
              border-radius: 0 1rem 1rem 0;
            }
          }
        }

        &.expandedRow {
          :global(.row) {
            margin: unset;
          }
        }
      }
    }
  }

  .tableCombination {
    tbody {
      tr {
        td {
          max-width: 10rem;
          text-align: left;
          padding: 0.25rem 0.5rem;

          &:first-child {
            p {
              margin-bottom: 10px;
              white-space: break-spaces;

              &:last-of-type {
                margin-bottom: 0;
              }

              span {
                color: var(--bs-secondary);
                font-weight: bold;
              }
            }
          }

          &:last-of-type {
            text-align: center;
          }
        }
      }
    }
  }

  .inputQuantity {
    button {
      padding: 0 5px;
    }
    input[type='number'] {
      font-size: 16px;
    }
  }
}

@media (max-width: 520px) {
  .quantityButtons {
    :global(.col-input) {
      max-width: 110px;
    }
  }

  .inputQuantity {
    max-width: 150px;
  }

  .tableCombination {
    tbody {
      tr {
        td {
          max-width: 8rem;
        }
      }
    }
  }

  .grid {
    .gridHeader {
      img {
        height: 120px;
      }
    }
    .gridBody {
      font-size: 0.8rem;

      p {
        &:first-of-type {
          height: 35px;
        }
      }

      strong {
        font-size: 0.9rem;
      }
    }
  }

  .pills {
    div {
      &:first-of-type {
        padding: 0.1rem 0.75rem;
      }
    }

    button {
      padding: 0.1rem 0.75rem;
    }
  }

  .breadcrumb {
    button {
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 390px) {
  .optionButtons {
    button {
      font-size: 15px;
      margin-right: 0;
    }
  }

  .inputQuantity {
    max-width: 110px;
    input[type='number'] {
      font-size: 1rem;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .viewButtons {
    button {
      padding: 0.35rem 0.6rem;
      svg {
        font-size: 16px;
      }
    }
  }

  .table {
    tbody {
      tr {
        td {
          max-width: 11rem;

          .expandedRowToggle {
            font-size: 15px;
          }
        }
      }
    }
  }

  .tableCombination {
    tbody {
      tr {
        td {
          max-width: 7rem;
        }
      }
    }
  }
}
