.container {
  max-height: 100vh;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

  .rightColumn {
    position: relative;
    height: 100vh;
    background-color: var(--bs-primary);
    display: flex;

    h1 {
      color: var(--bs-light);
      font-size: 4rem;
      margin-bottom: 3rem;

      span {
        font-size: 6rem;
      }
    }

    & > :global(.container) {
      margin-top: auto;
      margin-bottom: auto;
    }

    :global(.invalid-feedback) {
      color: var(--bs-light);
    }

    input {
      &::placeholder {
        color: var(--bs-dark);
      }
    }

    button[type='submit'] {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  .circles {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-height: 100vh;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    align-items: center;

    img {
      margin-left: -35rem;
      margin-top: -17rem;
    }
  }

  .woman {
    position: absolute;
    right: -1.5rem;
    bottom: 0;
    z-index: 1;
  }

  .man {
    position: absolute;
    left: -1.5rem;
    bottom: 0;
    z-index: 1;
  }
}
